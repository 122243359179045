export function convertDateToParamAsIsoDate(dateTime: Date): string {
    return dateTime.toISOString().replace(/\..*/gi, '');

    const pad = '0';
    const year = padLeft(dateTime.getFullYear().toString(), pad, 4);
    // ! нумерация месяцев начинается с нуля
    const month = padLeft((dateTime.getMonth() + 1).toString(), pad, 2);
    const date = padLeft(dateTime.getDate().toString(), pad, 2);
    const hours = padLeft(dateTime.getHours().toString(), pad, 2);
    const minutes = padLeft(dateTime.getMinutes().toString(), pad, 2);
    const seconds = padLeft(dateTime.getSeconds().toString(), pad, 2);
    //const ms = padLeft(dateTime.getMilliseconds().toString(), pad, 3);

    //return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.${ms}`;
    return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}`;
}


function padLeft(value: string, pad: string, maxLength: number) {
    if (value.length >= maxLength || maxLength <= 0 || pad.length <= 0) {
        return value;
    }

    while (value.length < maxLength) {
        value = pad + value;
    }

    return value;
}


export function jsonReplacer(key: any, value: any) {
    // value - уже строка
    //@ts-ignore
    const sourceValue = this[key];
    //console.log(`key: ${key}\nthis: ${JSON.stringify(this)}\nvalue: ${value}\nsourceValue: ${sourceValue}`)
    if (sourceValue === null || sourceValue === undefined) {
        return null;
    }

    if (sourceValue instanceof Date) {
        return convertDateToParamAsIsoDate(sourceValue as Date);
    }

    return value;
}