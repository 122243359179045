const TAXI_PARAMS = {
    PUBLIC_URL: process.env.PUBLIC_URL,
    WEBSERVER_URL: process.env.REACT_APP_WEBSERVER_URL,
    WEBAPI_URL: process.env.REACT_APP_WEBAPI_URL,
    WEBSERVER_PORT: parseInt(process.env.REACT_APP_WEBSERVER_PORT || ''),
    SIP_PORT: parseInt(process.env.REACT_APP_SIP_PORT || ''),
    SIP_AGENT_NUMBER: process.env.REACT_APP_SIP_AGENT_NUMBER,
    SIP_URI: process.env.REACT_APP_SIP_URI,
    WEBSERVER_APIKEY: process.env.REACT_APP_WEBSERVER_APIKEY,
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    DEFAULT_LANG: process.env.REACT_APP_DEFAULT_LANG,
    DEFAULT_COUNTRY: process.env.REACT_APP_DEFAULT_COUNTRY,
    DEFAULT_TITLE: process.env.REACT_APP_DEFAULT_TITLE,
    LAT: process.env.REACT_APP_LAT,
    LNG: process.env.REACT_APP_LNG,
    SERVER_API_URL: process.env.REACT_APP_SERVER_API_URL
}


// проверка что все переменные заданы или имеют значение по дефолту

for(const paramName in TAXI_PARAMS){
    const typedParamName = paramName as keyof typeof TAXI_PARAMS;
    const paramValue : string | number | undefined = TAXI_PARAMS[typedParamName];
    if (paramValue === undefined
        || (typeof paramValue === 'number' && isNaN(paramValue as number))){
        throw new Error(`Invalid value '${paramValue}' of environment variable '${paramName}'`);
    }
}


export default TAXI_PARAMS;