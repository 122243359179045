import { ActionCreator, Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RegistrationActionTypes } from "./types";
import { getWebServerProxy, initWebServerProxy } from '../../webServer';
import { 
    ISendActivationKeyAction, 
    IActivationKeyAction,
    ISetUserKey,
    IGetPhoneAction,
    IGetErrorAction,
    IActivationKeyOpenAction,
    ICurrentTariffOpenAction,
    ICalcCostProcessingAction,
    IGetRouteProcessingAction
} from './interface';
import { 
    ISendActivationKey, 
    LoginWithActivationKeyInfo, 
    SendActivationKeyResult 
} from '../../webServer/controllers/commonWeb/types/type';
import { userInitWebServerProxy } from '../../utils/initWebServerProxy';

export type RegistrationActions =
    | ISendActivationKeyAction
    | IActivationKeyAction
    | ISetUserKey
    | IGetPhoneAction
    | IGetErrorAction
    | IActivationKeyOpenAction
    | ICurrentTariffOpenAction
    | ICalcCostProcessingAction
    | IGetRouteProcessingAction;

export const sendActivationKeyAction: ActionCreator<ThunkAction<Promise<any>, any, string, ISendActivationKeyAction>> =
    (parameter: ISendActivationKey) => {
        return async (dispatch: ThunkDispatch<any, string, Action<any>>) => {
            try {
                userInitWebServerProxy();
                const wsProxy = getWebServerProxy();
                const response = await wsProxy.webServersCommonWeb.SendActivationKey(parameter);
                if (response.Success === false) {
                    throw new Error(`Search error ${response.Message}`);
                }

                if (response.Result!.result === SendActivationKeyResult.mobilePhoneIncorrect 
                    || response.Result!.result === SendActivationKeyResult.error) {

                    const failAction: IGetErrorAction = {
                        type: RegistrationActionTypes.GET_ERROR,
                        error: 'Enter the correct phone number'
                    };
    
                    dispatch(failAction);
                } else {
                    const successAction: ISendActivationKeyAction = {
                        type: RegistrationActionTypes.SEND_ACTIVATION_KEY,
                        result: response.Result!.result,
                        activationKeyOpen: true,
                    };

                    dispatch(successAction);
                }

            }
            catch (e) {
                console.log(`Ошибка: ${e}`);
            }
        }
    }

export const loginWithActivationKeyAction: ActionCreator<ThunkAction<Promise<any>, any, string, IActivationKeyAction>> =
    (parameter: LoginWithActivationKeyInfo) => {
        return async (dispatch: ThunkDispatch<any, string, Action<any>>) => {
            try {
                userInitWebServerProxy();
                const wsProxy = getWebServerProxy();
                const response = await wsProxy.webServersCommonWeb.LoginWithActivationKey(parameter);
                if (response.Success === false) {
                    const failAction: IGetErrorAction = {
                        type: RegistrationActionTypes.GET_ERROR,
                        error: response.Message || 'Something went wrong'
                    };
                    return dispatch(failAction);
                }

                const successAction: IActivationKeyAction = {
                    type: RegistrationActionTypes.ACTIVATION_KEY,
                    activationKeyResult: response.Result,
                };

                dispatch(successAction);

                // return window.location.href = '/';

            }
            catch (e) {
                console.log(`Ошибка: ${e}`);
            }
        }
    }

export const activationKeyOpenAction: ActionCreator<IActivationKeyOpenAction> = (activationKeyOpen: boolean) => {
    return {
        type: RegistrationActionTypes.ACTIVATION_KEY_OPEN,
        activationKeyOpen
    }
}

export const currentTariffOpenAction: ActionCreator<ICurrentTariffOpenAction> = (currentTariffOpen: boolean) => {
    return {
        type: RegistrationActionTypes.CURRENT_TARIFF_OPEN,
        currentTariffOpen
    }
}

export const calcCostProcessingAction: ActionCreator<ICalcCostProcessingAction> = (calcCostProcessing: boolean) => {
    return {
        type: RegistrationActionTypes.CALC_COST_PROCESSING,
        calcCostProcessing
    }
}

export const getRouteProcessingAction: ActionCreator<IGetRouteProcessingAction> = (getRouteProcessing: boolean) => {
    return {
        type: RegistrationActionTypes.GET_ROUTE_PROCESSING,
        getRouteProcessing
    }
}