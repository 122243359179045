import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import {
  IonModal,
  IonHeader,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonIcon,
} from '@ionic/react';
import { chevronBack, checkmarkCircle, searchSharp, closeSharp } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import './SearchAdress.scss';
import WidgetAutocomplete from '../WidgetAutocomplete/WidgetAutocomplete';
import { AddressAPI, AddressAutocomplete, AddressCenter, IAddressParameters, IGetAddressItem } from 'sedi-webserverproxy';
import TAXI_PARAMS from '../../utils/build';

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & {
        searchOpen: boolean;
        handleSearchToggle: () => void;
};

const SearchAdress: React.FC<Props> = (props) => {
    // Для инициализации url
    AddressAPI.initialization(`${TAXI_PARAMS.SERVER_API_URL}:12013`);
    const helperNew = new AddressAutocomplete(new AddressAPI());
    const { t } = useTranslation();

    const {
        searchOpen,
        handleSearchToggle,
        center,
        valueFromView,
        valueFromViewAction,
        currentAddress,
        currentAddressAction,
        lang,
        setRoutePointAction,
        addressCenterAction,
        setPointAutocomplateAction,
        addressMethod
    } = props;

    const [addressItems, setAddressItems] = useState<IGetAddressItem[]>([]);

    const doneClick = async () => {
        let parameters: IAddressParameters;
        if(currentAddress) {
            if(currentAddress.t === "s" && addressMethod === "click") {
                console.log(currentAddress.g);
                setRoutePointAction(currentAddress);
                addressCenterAction({ 
                    lat: currentAddress.g.lat, 
                    lon: currentAddress.g.lon 
                });
                setPointAutocomplateAction(true);
                handleSearchToggle();
                return;
            }
            parameters = {
                searchText: valueFromView.replace(/[,]/g, ''),
                searchType: null,
                city: currentAddress.c,
                street: currentAddress.s,
                lat: currentAddress.g.lat,
                lon: currentAddress.g.lon,
                countryName: currentAddress.co,
                searchMethod: "CheckAddress",
                lang
            }
        } else {
            parameters = {
                searchText: valueFromView.replace(/[,]/g, ''),
                searchType: null,
                searchMethod: "CheckAddress",
                countryName: TAXI_PARAMS.DEFAULT_COUNTRY,
                lang
            }
        }
        const response = await helperNew.GetAddresses(parameters);
        if (response.Success === false) {
            return alert(response.Message);
        }

        setAddressItems(response.Result!.items);
    };

    const addressChange = (value: string) => {
        valueFromViewAction(value);
    }

    const clearInputClick = () => {
        addressChange("");
        currentAddressAction(null);
    }

    return (
        <IonModal isOpen={searchOpen}>
            <IonHeader className="searchHeader">
                <IonToolbar>
                    <IonTitle>{t('search')}</IonTitle>
                    <IonButtons slot="start" onClick={() => handleSearchToggle()}>
                        <IonIcon icon={chevronBack} />
                    </IonButtons>
                    <IonButtons 
                        className="doneIcon" 
                        slot="end" 
                        onClick={doneClick}>
                        <IonIcon icon={checkmarkCircle} />
                    </IonButtons>
                </IonToolbar>
                <div className="searchBar">
                    <IonIcon 
                        icon={searchSharp} 
                        class="searchBar__icon search"
                        style={lang === "he" ? {right: 20, left: 'auto'} : {}} />
                    <WidgetAutocomplete 
                        value={valueFromView}
                        inputClassName="searchBar__input"
                        inputClassNameLoading={lang === "he" ? "searchBar__input__loading_he" : "searchBar__input__loading"}
                        onChangeInput={(newValue: string) => addressChange(newValue)} 
                        cityClassName="searchBar__city" 
                        lat={center.lat}
                        lon={center.lon}
                        countryName={TAXI_PARAMS.DEFAULT_COUNTRY} 
                        placeholder={t('search')} 
                        handleSearchToggle={handleSearchToggle}
                        externalAddress={currentAddress ? currentAddress : undefined} 
                        addressItems={addressItems} 
                        />
                    <IonIcon 
                        icon={closeSharp} 
                        class="searchBar__icon close"
                        onClick={clearInputClick} 
                        style={lang === "he" ? {right: 'auto', left: 20} : {}}/>
                </div>
            </IonHeader>
        </IonModal>
    );
};

const mapStateToProps = (state: RootState) => ({
    center: state.map.center,
    valueFromView: state.map.valueFromView,
    currentAddress: state.map.currentAddress,
    lang: state.map.lang,
    addressMethod: state.map.addressMethod
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    addressCenterAction: (center: AddressCenter) =>
        dispatch(actions.map.addressCenterAction(center)),
    valueFromViewAction: (valueFromView: string) =>
        dispatch(actions.map.valueFromViewAction(valueFromView)),
    setRoutePointAction: (routePoint: IGetAddressItem) =>
        dispatch(actions.map.setRoutePointAction(routePoint)),
    setPointAutocomplateAction: (changePointAutocomplate: boolean) =>
        dispatch(actions.map.setPointAutocomplateAction(changePointAutocomplate)),
    currentAddressAction: (currentAddress: IGetAddressItem | null) =>
        dispatch(actions.map.currentAddressAction(currentAddress)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchAdress);
