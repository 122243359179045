import { ApiResponse } from "../../types";
import { WebServerBase } from '../base/WebServerBase';
import { LoginWithActivationKeyInfo, ISendActivationKey } from "./types/type";


const controllerName = 'CommonWeb';


export class WebServerCommonWeb {

    private _webServer: WebServerBase;

    
    constructor(webServer: WebServerBase) {
        this._webServer = webServer;
    }

    public async SendActivationKey(parameter: ISendActivationKey): Promise<ApiResponse<any>> {
        const response = await this._webServer.fetchPOST<any[]>(controllerName, 'SendActivationKey', parameter);
        return response as ApiResponse<any[]>;
    }

    public async LoginWithActivationKey(parameter: LoginWithActivationKeyInfo): Promise<ApiResponse<any>> {
        const response = await this._webServer.fetchPOST<any[]>(controllerName, 'LoginWithActivationKey', parameter);
        return response as ApiResponse<any[]>;
    }
}